import { useState } from 'react';
import { WizardPicto } from '@platform-storybook/circlestorybook';
import styles from './create-order-page.module.scss';
import PatientFilesStep from './patient-files-step/PatientFilesStep.tsx';
import PlanStep from './plan-step/PlanStep.tsx';
import DesignStep from './design-step/DesignStep.tsx';
import { WizardPictoStep } from '../../../models/navigation.tsx';
import ExportStep from './download-step/ExportStep.tsx';
import { getWizardSteps } from './create-order-page.utils.ts';

const CreateOrderPage = () => {
  const [selectedStepIndex, setSelectedStepIndex] = useState<number>(0);
  const [wizardSteps, setWizardSteps] = useState<Array<WizardPictoStep>>(getWizardSteps());

  const navigateNextStep = () => {
    const newStepIndex = selectedStepIndex + 1;
    setSelectedStepIndex(newStepIndex);
    setWizardSteps(getWizardSteps(newStepIndex));
  };

  return (
    <div className={styles['create-order-page']}>
      <WizardPicto options={wizardSteps} className={styles['create-order-page__wizard']} />
      <div className={styles['create-order-page__content']}>
        {selectedStepIndex === 0 && <PatientFilesStep nextCallback={navigateNextStep} />}
        {selectedStepIndex === 1 && <PlanStep nextCallback={navigateNextStep} />}
        {selectedStepIndex === 2 && <DesignStep nextCallback={navigateNextStep} />}
        {selectedStepIndex === 3 && <ExportStep />}
      </div>
    </div>
  );
};

export default CreateOrderPage;
