import {
  IconButton,
  Text,
  TextField,
  Tooltip,
  TooltipContent
} from '@platform-storybook/circlestorybook';

import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks.tsx';
import { orderSelector } from '../../../store/orders/orders.selectors.tsx';
import { ColorPropsEnum } from '../../../enum/color.enum.ts';
import { useEffect, useState } from 'react';
import { ordersActions } from '../../../store/orders/orders.reducer.tsx';
import { Order, OrderForCreation, OrderForUpdate } from '../../../models/order.tsx';
import useForm from '../../../utils/useForm.tsx';
import styles from './patient-reference-form.module.scss';
import { usePatchOrderMutation } from '../../../services/orders-api.services.ts';

const PatientReferenceForm = () => {
  // Redux
  const order = useAppSelector(orderSelector);
  const [patchOrder] = usePatchOrderMutation();

  // Local
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialPatientReference = order?.patient?.reference ?? searchParams.get('patientReference');
  const [displayEditForm, setDisplayEditForm] = useState<boolean>(false);

  // Event handlers
  const onEditButtonClick = () => {
    setDisplayEditForm(true);
  };

  const onSubmit = () => {
    setDisplayEditForm(false);

    // Save in state
    const updatedOrder = {
      ...order,
      patient: { ...order?.patient, reference: values.patientReference }
    } as Order;
    dispatch(ordersActions.setOrder(updatedOrder as OrderForUpdate));

    // Refresh url
    setSearchParams({ patientReference: encodeURIComponent(values.patientReference as string) });

    // If order has already been saved, we patch it
    if (updatedOrder.id) {
      patchOrder({
        orderNumber: updatedOrder.orderNumber,
        patient: { reference: updatedOrder.patient?.reference }
      });
    }
  };

  // Form
  const { values, handleChange, handleSubmit } = useForm(
    { patientReference: initialPatientReference },
    onSubmit
  );

  // Use effects
  useEffect(() => {
    // If page has been refreshed, patient reference was lost
    // That's the reason why we set it in url to begin with
    // So we reset it here
    if (initialPatientReference && !order?.patient?.reference) {
      dispatch(
        ordersActions.setOrder({
          ...(order as OrderForCreation),
          patient: { reference: initialPatientReference }
        })
      );
    }
  }, [initialPatientReference]);

  return initialPatientReference ? (
    <>
      {displayEditForm && (
        <form
          className={styles['patient-reference-form__form']}
          data-cy="patient-reference-form"
          onSubmit={handleSubmit}>
          <TextField
            id="patientReference"
            name="patientReference"
            value={values.patientReference}
            type="text"
            onChange={handleChange}
            data-cy="patient-reference-form-input"
            className={styles['patient-reference-form__form__input']}
            radius="full"
          />
          <IconButton
            faIconClass="check"
            radius="full"
            iconStyle="regular"
            color={ColorPropsEnum.SUCCESS}
            data-cy="patient-reference-form-submit"
            onClick={handleSubmit}
          />
        </form>
      )}
      {!displayEditForm && (
        <div data-cy="patient-reference" className={styles['patient-reference-form__content']}>
          <Tooltip>
            <TooltipContent>{values.patientReference}</TooltipContent>
            <Text
              data-cy="patient-reference-value"
              label={values.patientReference}
              size="l"
              className={styles['patient-reference-form__content__reference']}
            />
          </Tooltip>
          <IconButton
            faIconClass="pencil"
            radius="full"
            iconStyle="regular"
            color={ColorPropsEnum.PRIMARY}
            onClick={onEditButtonClick}
            data-cy="patient-reference-start-edit-button"
            iconSize="sm"
          />
        </div>
      )}
    </>
  ) : (
    <></>
  );
};

export default PatientReferenceForm;
