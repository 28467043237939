import { useCallback, useEffect, useRef, useState } from 'react';
import { useLazyGetOrdersQuery } from '../../../services/orders-api.services';
import { Button, Text, Search } from '@platform-storybook/circlestorybook';
import OrderCard from './order-card/OrderCard';
import styles from './treatments-page.module.scss';
import { OrderForCreation, OrderForList } from '../../../models/order';
import { useNavigate } from 'react-router-dom';
import SkeletonOrdersList from './skeleton-orders-llist/SkeletonOrdersList';
import { useTranslation } from 'react-i18next';
import { ordersActions } from '../../../store/orders/orders.reducer';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { orderSelector } from '../../../store/orders/orders.selectors';
import TreatmentTypeModal from './treatment-type-modal/TreatmentTypeModal';

const TreatmentsPage = () => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(1);
  const [getOrders, { isLoading }] = useLazyGetOrdersQuery();
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [ordersList, setOrdersList] = useState<OrderForList[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);
  const [isTreatmentTypeModalOpened, setIsTreatmentTypeModalOpened] = useState<boolean>(false);
  const observer = useRef<IntersectionObserver | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation(['treatments']);
  const order = useAppSelector(orderSelector);

  useEffect(() => {
    if (totalPages && page > totalPages) {
      return;
    }
    fetchOrders();
  }, [page, searchValue]);

  const LIMIT = 16;
  const skeletonNumber = Math.round(LIMIT / 2);

  // Debounce: Wait the user to stop typing
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const fetchOrders = async () => {
    try {
      const { data: result, meta } = await getOrders({
        page: page,
        limit: LIMIT,
        filtersQuery: '',
        searchQuery: searchValue
      }).unwrap();

      setOrdersList((prevOrders) => {
        // we check if we are in page === 1 or the prev result === current result we reinitialize the order list state
        if (JSON.stringify(prevOrders) === JSON.stringify(result) || page === 1) {
          // Because in dev with React.StrictMode, the component is build twice
          return [...result];
        }
        return [...prevOrders, ...result];
      });

      // set totalPages once
      if (meta.currentPage === 1) {
        setTotalPages(meta.totalPages);
      }
    } catch {
      setError(true);
    }
  };

  // For infinite scrolling
  const lastOrderCardElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (isLoading) return;
      // To avoid observing multiple times
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            // the observed element is in the viewport, 100% visible
            setPage((prevPage) => {
              return prevPage + 1;
            });
          }
        },
        { threshold: 0.5 }
      );

      if (node) observer.current.observe(node);
    },
    [isLoading]
  );

  // Function handle on change search input with a delay of 300ms
  const handleChangeSearch = (searchInput: string) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    setSearchInputValue(searchInput);
    // Delay to change the search value
    debounceTimeout.current = setTimeout(() => {
      setSearchValue(searchInput);
      setPage(1); // reinitialize the page to 1 on a new search.
    }, 400); // delay of 400ms
  };

  const submit = (reference: string) => {
    dispatch(ordersActions.resetOrder());
    dispatch(
      ordersActions.setOrder({
        ...(order as OrderForCreation),
        patient: { reference }
      })
    );
    navigate(`/order/create?patientReference=${encodeURIComponent(reference)}`);
  };

  return (
    <section className={styles['treatments-page']}>
      <TreatmentTypeModal
        isOpened={isTreatmentTypeModalOpened}
        closeOnOutsideClick
        onOutsideClick={() => setIsTreatmentTypeModalOpened(false)}
        onSubmit={(reference) => submit(reference)}
      />
      <header className={styles['treatments-page__header']}>
        <Text size="s" type="title" label={t('title')} data-cy="treatments-title" />
        <div className={styles['treatments-page__header__search-create-button']}>
          <Search
            data-cy="treatments-search"
            placeholder={t('search.placeholder')}
            onChange={(searchInput: string) => handleChangeSearch(searchInput)}
            value={searchInputValue}
          />
          <Button
            data-cy="treatments-fab"
            label={t('button.label.fab')}
            iconLeft="fa-plus"
            onClick={() => {
              setIsTreatmentTypeModalOpened(true);
            }}
          />
        </div>
      </header>
      <div className={styles['treatments-page__cards']}>
        {!isLoading && (
          <>
            {ordersList.length > 0 ? (
              ordersList?.map((order, index) => (
                <OrderCard
                  data-cy={`treatments-title-${order.id}`}
                  key={order.id}
                  order={order}
                  // Attach lastOrderCardElementRef to the last card
                  ref={
                    index === ordersList.length - 1 && totalPages && totalPages > 1
                      ? lastOrderCardElementRef
                      : null
                  }
                />
              ))
            ) : (
              <Text data-cy="treatments-not-found" label={t('search.resultNotFound')} />
            )}
          </>
        )}
        {totalPages > 0 && !error && page < totalPages && (
          <SkeletonOrdersList count={skeletonNumber} />
        )}
      </div>
    </section>
  );
};
export default TreatmentsPage;
