import { Toast } from '@platform-storybook/circlestorybook';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { history } from '../../models/history.tsx';
import { useTranslation } from 'react-i18next';
import style from './private-app.module.scss';
import { toastSelector } from '../../store/feedback/feedback.selector';
import { feedbackActions } from '../../store/feedback/feedback.reducer';
import { api } from '../../services/api.ts';
import { useEffect, useState } from 'react';
import { resetAll } from '../../store';
import PrivateAppHeader from '../../features/private-app-header/PrivateAppHeader.tsx';
import PatientReferenceForm from '../../features/private-app-header/patient-reference-form/PatientReferenceForm.tsx';

const PrivateApp = ({
  displayAdditionnalsFeaturesOnHeader = false
}: {
  displayAdditionnalsFeaturesOnHeader?: boolean;
}) => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();
  history.navigate = useNavigate();

  const toast = useAppSelector(toastSelector);
  const [isResetStore, setIsResetStore] = useState<boolean>(false);

  // Use Effects
  useEffect(() => {
    dispatch(resetAll());
    dispatch(api.util.resetApiState());
    setIsResetStore(true);
  }, []);

  return (
    isResetStore && (
      <div className={style['private-app']} data-cy="privateApp">
        {toast && (
          <Toast
            data-cy={`toast-${toast.type}`}
            message={'message' in toast ? toast.message : t('generic', { ns: 'error' })}
            onClose={() => {
              dispatch(feedbackActions.resetToast());
            }}
            autoHideDuration={toast.type === 'danger' ? 8000 : 5000}
            type={toast.type}
          />
        )}
        {displayAdditionnalsFeaturesOnHeader ? (
          <PrivateAppHeader>
            <PatientReferenceForm />
          </PrivateAppHeader>
        ) : (
          <PrivateAppHeader />
        )}

        <main className={style['private-app__main']}>
          <Outlet />
        </main>
      </div>
    )
  );
};
export default PrivateApp;
