import { Avatar, Chips, DropdownMenu, Header } from '@platform-storybook/circlestorybook';
import { ColorPropsEnum } from '../../enum/color.enum.ts';
import { OrderForUpdate } from '../../models/order.tsx';
import { useAppDispatch, useAppSelector } from '../../store/hooks.tsx';
import { isOrderStreamingSelector, orderSelector } from '../../store/orders/orders.selectors.tsx';
import { useEndTaskMutation } from '../../services/manufacturing-orders-api.services.tsx';
import { authActions } from '../../store/auth/auth.reducers.tsx';
import { connectedUserSelector } from '../../store/auth/auth.selectors.tsx';
import { useTranslation } from 'react-i18next';
import { viteMode } from '../../utils/utils.tsx';
import { ReactNode } from 'react';
import styles from './private-app-header.module.scss';

const PrivateAppHeader = ({ children }: { children?: ReactNode }) => {
  // Redux
  const isStreaming = useAppSelector(isOrderStreamingSelector);
  const [endTask] = useEndTaskMutation();
  const connectedUser = useAppSelector(connectedUserSelector);
  const order = useAppSelector(orderSelector);

  // Local
  const { t } = useTranslation(['common']);
  const envLabel = viteMode();
  const dispatch = useAppDispatch();

  // Functions
  const getAvatarLetter = () => {
    return connectedUser?.email?.substring(0, 1).toUpperCase();
  };

  const contentAvatarMenu = [
    [{ label: t('link.myProfile'), type: 'nav', link: '/profile', disabled: true }],
    [
      {
        label: t('link.logout'),
        type: 'button',
        onClick: async () => {
          if (isStreaming) {
            await endTask((order as OrderForUpdate).orderNumber as string);
          }
          dispatch(authActions.logout());
        }
      }
    ]
  ];

  return (
    <Header logoClass="logo-circle-title-purple" color="grey">
      <>
        <div className={styles['private-app-header__content']}>
          {children}
          {envLabel && <Chips firstLabel={envLabel} color={ColorPropsEnum.PRIMARY} />}
        </div>
        <nav className={styles['private-app-header__nav']}>
          <DropdownMenu
            renderTargetButton={(props: { active: boolean }) => (
              <Avatar
                label={getAvatarLetter() || ''}
                isActive={props.active}
                cursorPointer={true}
                className={styles['private-app-header__nav__avatar']}
              />
            )}
            data={contentAvatarMenu}
          />
        </nav>
      </>
    </Header>
  );
};

export default PrivateAppHeader;
